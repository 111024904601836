import { createApp } from 'vue'

import components from '@/components/_components'
import VueSplide from '@splidejs/vue-splide';
import VueCountdown from '@chenfengyuan/vue-countdown'

import MatchMedia from '@/services/MatchMedia'

import mobileMenuStore from '@/store/mobileMenu'
import progressBarStore from '@/store/progressBar'

const AppConfig = {
    compilerOptions: {
        delimiters: ["${", "}"]
    },
    provide() {
        return {
            mobileMenuStore,
            progressBarStore
        }
    },
    components,
    data() {
        return {
            importMobileComponents: false
        }
    },
    mounted() {
        new MatchMedia().mobile(() => { this.importMobileComponents = true })
    }
}

const app = createApp(AppConfig)
app.use(VueSplide)
app.component(VueCountdown.name, VueCountdown)
app.mount('#app')
