<template>
    <button type="submit" :class="{ [loadingClass]: isClicked }" @click.once="handleClick">
        <slot>Submit</slot>
    </button>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
    showLoading: {
        default: true
    }
})

const loadingClass = computed(() => props.showLoading && 'is-loading is-loading--small is-loading--white')
const isClicked = ref(false)

function handleClick() {
    isClicked.value = true
}
</script>