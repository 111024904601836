<template>
    <form method="POST" accept-charset="UTF-8" @submit="submitForm">
        <slot name="hidden-inputs"></slot>

        <div>
            <label class="text-2xl mb-0" for="loginName">Email</label>
            <input
                id="loginName" 
                type="email" 
                :name="LOGIN_NAME" 
                placeholder="email@site.com" 
                v-model="email"
            />
            <p v-if="emailErrorMessage" class="bg-red-100 text-red-800 p-2 px-3 rounded-b mb-4">{{ emailErrorMessage }}</p>
        </div>

        <div>
            <label class="text-2xl mb-0 mt-3" for="password">Password</label>
            <input 
                id="password" 
                type="password" 
                :name="PASSWORD" 
                v-model="password"
            />
            <p v-if="passwordErrorMessage" class="bg-red-100 text-red-800 p-2 px-3 rounded-b mb-4">{{ passwordErrorMessage }}</p>
        </div>

        <button type="submit" class="mt-4 btn float-right">Log In</button>

        <label class="text-2xl mt-3">
            <input type="checkbox" name="rememberMe" value="1" />
            Remember me
        </label>
    </form>
</template>

<script setup>
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

const props = defineProps({
    rememberedUsername: {
        type: String,
        default: ''
    }
});

const LOGIN_NAME = 'loginName'
const PASSWORD = 'password'
const validationSchema = yup.object({
    [LOGIN_NAME]: yup.string().required().email().label('Email'),
    [PASSWORD]: yup.string().required().label('Password')
});
const initialValues = {
    [LOGIN_NAME]: props.rememberedUsername,
    [PASSWORD]: ''
}
const { submitForm } = useForm({ validationSchema, initialValues });
const { value: email, errorMessage: emailErrorMessage } = useField(LOGIN_NAME);
const { value: password, errorMessage: passwordErrorMessage } = useField(PASSWORD);
</script>