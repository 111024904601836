import Dom from './Dom'

class Nav {
    constructor(obj) {
        this.activeClass = 'active'
        this.topLevelLinks = null
        this.activeTopLevelLink = null
        this.megaMenuPanels = null
        this.activeMegaMenuPanel = null
        this.megaMenuLinks = null
        this.closeOnClickOutside = false

        // Set method bindings
        this.handleClickForTopLevelLink = this.handleClickForTopLevelLink.bind(this)
        this.handleClickForMegaMenuLink = this.handleClickForMegaMenuLink.bind(this)
        this.showMegaMenuPanel = this.showMegaMenuPanel.bind(this)
        this.addActiveClass = this.addActiveClass.bind(this)
        this.removeActiveClass = this.removeActiveClass.bind(this)
        this.closeNav = this.closeNav.bind(this)
        this.handleCloseOnClickOutside = this.handleCloseOnClickOutside.bind(this)

        // Set Nav data and event listeners
        if (obj) {
            for (let key in obj) {
                this[key] = obj[key]
            }
            this.addEventsToTopLevelLinks(this.topLevelLinks)
            this.addEventsToMegaMenuLinks(this.megaMenuLinks)
            this.handleCloseOnClickOutside(this.closeOnClickOutside)
        }
    }

    addEventsToTopLevelLinks(links) {
        if (links && Array.isArray(links)) {
            this.topLevelLinks.forEach(link => {
                link.addEventListener('click', this.handleClickForTopLevelLink)
            })
        }
    }

    addEventsToMegaMenuLinks(links) {
        if (links && Array.isArray(links)) {
            this.megaMenuLinks.forEach(link => {
                link.addEventListener('click', this.handleClickForMegaMenuLink)
            })
        }
    }

    handleClickForTopLevelLink(e) {
        e.preventDefault()
        e.stopPropagation()

        const targetNavId = e.target.dataset.gotonav
        const parent = e.target.parentNode

        if (parent === this.activeTopLevelLink) {
            this.closeNav()
        } else {
            // If a tab is already open, close it first
            if (this.activeTopLevelLink && this.activeMegaMenuPanel) {
                this.closeNav()
            }

            this.activeTopLevelLink = parent
            // Target the parent element with class .has-sub and add the .active class
            this.addActiveClass(parent)

            // Target the element with a data nav of <X> and add the .active class
            this.showMegaMenuPanel(targetNavId)
        }
    }

    handleClickForMegaMenuLink(e) {
        e.preventDefault()
        e.stopPropagation()

        const targetMegaMenuPanel = e.target.dataset.gotonav
        this.showMegaMenuPanel(targetMegaMenuPanel)
    }

    showMegaMenuPanel(id = null) {
        // If a panel is open, close it before proceeding
        if (this.activeMegaMenuPanel) {
            this.removeActiveClass(this.activeMegaMenuPanel)
            this.activeMegaMenuPanel = null
        }
        const menuToReveal = this.megaMenuPanels.find(menu => menu.dataset?.nav === id)
        this.addActiveClass(menuToReveal)
        this.activeMegaMenuPanel = menuToReveal
    }

    closeNav() {
        this.removeActiveClass(this.activeTopLevelLink, this.activeMegaMenuPanel)
        this.activeTopLevelLink = null
        this.activeMegaMenuPanel = null
    }

    handleCloseOnClickOutside(verdict) {
        if (verdict && Dom.body) {
            Dom.body.addEventListener('click', this.closeNav)
        }

        // Make sure we don't close the nav if we click anywhere inside of it
        if (this.megaMenuPanels) {
            this.megaMenuPanels.forEach(panel => {
                panel.addEventListener('click', e => (e.stopPropagation()))
            })
        }
    }

    // I'm aware I could have merged the below two methods into a single toggle but I liked it like this for readability
    addActiveClass(...els) {
        if (els) {
            els.forEach(el => el?.classList?.add(this.activeClass))
        }
    }

    removeActiveClass(...els) {
        if (els) {
            els.forEach(el => el?.classList?.remove(this.activeClass))
        }
    }
}

export default Nav