import * as Yup from 'yup'
import axios from 'axios'

Yup.addMethod(Yup.mixed, 'isImage', function isImage(errorMessage) {
    return this.test('test-is-image', errorMessage || '${path} must be an image', function(value) {
        const { path, createError } = this
        const supportedImageTypes = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
        return supportedImageTypes.includes(value && value.type) || createError({ path, message: errorMessage})
    })
})

Yup.addMethod(Yup.string, 'isUkPostcode', function isUkPostcode(errorMessage) {
    return this.test('test-is-uk-postcode', errorMessage || 'Must be a valid UK postcode', function(value) {
        const { path, createError } = this

        const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i
        return postcodeRegex.test(value) || createError({ path, message: errorMessage })
    })
})

Yup.addMethod(Yup.string, 'isPostcodeCovered', function isPostcodeCovered(errorMessage) {
    return this.test('is-postcode-covered', errorMessage || 'Sorry, we do not cover your postcode', function(value) {
        const { path, createError } = this
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/quotation/validate-postcode-coverage',
                data: {
                    postcode: value
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': window.csrfTokenValue,
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(response => {
                if (response.data && (response.status >= 200 && response.status < 300)) resolve(true)
                throw new Error()
            }).catch(err => {
                reject(createError({ path, message: errorMessage}))
            })
        })
    })
})

export default Yup