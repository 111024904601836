<template>
    <div>
        <div class="relative">
            <button
                type="button"
                @click.prevent="getLocation"
                class="absolute top-1/2 -translate-y-1/2 z-10 text-grey-dark-alt hover:text-orange cursor-pointer"
                :class="{ 'is-loading is-loading--small': ajaxIsLoading }"
                style="right: 1.25rem;"
            >
                <IconGps class="w-5 h-5 fill-current" />
            </button>
    
            <input
                type="text"
                placeholder="Postcode"
                id="postcode"
                name="quotation[your-details][postCode]"
                v-model="postcode"
            />
        </div>
        <p v-if="apiErrorMessage" class="form-field__error">{{ apiErrorMessage }}</p>
        <p v-else-if="errorMessage" class="form-field__error">{{ errorMessage }}</p>
    </div>
</template>

<script setup>
import { computed, ref, watch, nextTick } from 'vue'
import IconGps from '@/components/icons/IconGps.vue'
import axios from 'axios'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    errorMessage: {
        type: String,
        default: ''
    }
})

const postcode = computed({
    get: () => props.modelValue,
    set: postcode => emit('update:modelValue', postcode)
})
const ajaxIsLoading = ref(false)
const apiErrorMessage = ref('')

function getLocation() {
    // Hade to set ajaxIsLoading here to true and false in the handleGetCurrentPosition function
    // for the correct display and to prevent a weird bug where loading was never showing
    ajaxIsLoading.value = true
    navigator.geolocation.getCurrentPosition(handleGetCurrentPosition)
}

async function handleGetCurrentPosition(position) {
    try {
        const { data, status, error } = await axios({
            url: '/quotation/geocheck',
            method: 'POST',
            data: {
                lat: position.coords.latitude,
                long: position.coords.longitude
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': window.csrfTokenValue,
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        if (data && (status >= 200 && status < 300)) {
            postcode.value = data
        } else {
            throw error ? error : 'Something went wrong. We are not able to retrieve your postcode'
        }
    } catch (error) {
        if (typeof error === 'string') {
            apiErrorMessage.value = error
        } else if (error.hasOwnProperty('message')) {
            apiErrorMessage.value = error.message
        } else {
            apiErrorMessage.value = 'Something went wrong. We are not able to retrieve your postcode'
        }
        apiErrorMessage.value = apiErrorMessage.value + '. Please enter your postcode manually.'
    }
    ajaxIsLoading.value = false
}

watch(
    () => postcode.value,
    (newVal, oldVal) => {
        if (apiErrorMessage.value && newVal !== oldVal) {
            apiErrorMessage.value = ''
        }
    }
);
</script>

<style lang="scss" scoped>
.is-loading {
    position: absolute!important;
    outline: none;
}
</style>