import { reactive, readonly } from 'vue'

const state = reactive({
    isOpen: false
})

function toggle() {
    state.isOpen = !state.isOpen
}

function setIsOpen(val) {
    state.isOpen = val
}

export default {
    state: readonly(state),
    toggle,
    setIsOpen
}