// Import 3rd party packages
import inView from "in-view";
import StripePaymentGateway from "../classes/StripePaymentGateway";
import svg4everybody from "svg4everybody";
import Dom from "../classes/Dom";
import Nav from "../classes/Nav";

window.addEventListener("DOMContentLoaded", () => {
  // SVG. Provide cross browser support for using svg sprites.
  svg4everybody();

  // Show the appropriate variant information on the product single page when we change variants
  if (Dom.productVariantInputFields && Dom.productVariantInfoSections) {
    Dom.productVariantInputFields.forEach((field) => {
      field.addEventListener("change", (e) => {
        const id = e.target.value;
        Dom.productVariantInfoSections.forEach((section) => {
          const matchingId = section.dataset.variant === id;
          section.classList[matchingId ? "remove" : "add"]("hidden");
        });
        if (Dom.productVariantImages) {
          Dom.productVariantImages.forEach((image) => {
            const matchingId = image.dataset.imageVariant === id;
            image.classList[matchingId ? "remove" : "add"]("hidden");
          });
        }
      });
    });
  }

  // // Firefox bug fix ( IS THIS NECESSARY ? )
  // // Commented out as it was interfering with the transitions on the radio-colour inputs on the wallet template
  // if (Dom.inputs.length) {
  //   function toggleFocusClass(e) {
  //     const action = e.type === 'focus' ? 'add' : e.type === 'blur' ? 'remove' : 'remove'
  //     e.target.classList[action]('has-focus')
  //   }
  //   Dom.inputs.forEach(input => {
  //     input.addEventListener('focus', toggleFocusClass)
  //     input.addEventListener('blur', toggleFocusClass)
  //   })
  // }

  // Detect Edge
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    if (Dom.body) Dom.body.classList.add("is-edge");
  }

  // Meet the team view all
  if (Dom.btnMeetTeam && Dom.hiddenTeam) {
    Dom.btnMeetTeam.addEventListener("click", (e) => {
      e.preventDefault();
      const target = e.target;
      target.parentNode.removeChild(target);
      Dom.hiddenTeam.forEach((member) => member.classList.remove("hidden"));
    });
  }

  if (Dom.keyParts) {
    inView.offset({
      top: 400,
      bottom: 400,
    });

    inView(".js-key-parts").on("enter", function () {
      Dom.keyParts.classList.add("animate");
    });
    inView(".js-key-parts").on("exit", function () {
      Dom.keyParts.classList.remove("animate");
    });
  }

  // Main nav
  if (Dom.navTopLevelLinks && Dom.megaMenuPanels && Dom.megaMenuLinks) {
    // Remove pointer events from any of the nav children so it doesn't interfere with the Nav methods
    const allLinks = [...Dom.navTopLevelLinks, ...Dom.megaMenuLinks];
    allLinks.forEach((link) => {
      const children = Array.prototype.slice.call(link.children);
      if (children) {
        children.forEach((childEl) =>
          childEl?.classList?.add("pointer-events-none")
        );
      }
    });

    new Nav({
      topLevelLinks: Dom.navTopLevelLinks,
      megaMenuLinks: Dom.megaMenuLinks,
      megaMenuPanels: Dom.megaMenuPanels,
      closeOnClickOutside: true,
    });
  }

  // Stripe payment
  if (Dom.paymentIntentsForms) {
    const gateway = new StripePaymentGateway({
      paymentIntentsForms: Dom.paymentIntentsForms,
    });
    gateway.init();
  }
});
