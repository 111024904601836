<template>
    <form 
        action="https://3dautokeys.us12.list-manage.com/subscribe/post?u=ead77fd82e5ca29d3ba8dd58b&amp;id=70e08a1ac9" 
        method="post" 
        id="mc-embedded-subscribe-form" 
        name="mc-embedded-subscribe-form" 
        class="validate w-full" 
        target="_blank" 
        novalidate
        @submit="submitForm"
    >
        <slot name="csrf"></slot>
        <div id="mc_embed_signup_scroll">
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ead77fd82e5ca29d3ba8dd58b_70e08a1ac9" tabindex="-1" value=""></div>
            <div class="clear"></div>
                    
            <h3 class="text-grey-darkest text-2xl font-bold uppercase md:mb-0"><span class="text-orange">Subscribe</span> To Our Newsletter</h3>
            <div class="flex flex-col md:flex-row md:items-center">
                <div class="pr-6">
                    <p class="md:mb-0 text-grey-dark">{{ text }}</p>
                </div>
                <div class="flex-grow md:min-w-1/2">
                    <div class="flex">
                        <input
                            v-model="email"
                            type="text" 
                            name="EMAIL" 
                            id="mce-EMAIL" 
                            placeholder="Email address" 
                            class="required email border border-slate-200 px-2 mr-2 focus:border-orange outline-none min-w-0"
                        >
                        <input 
                            type="submit"
                            name="subscribe" 
                            value="Subscribe" 
                            id="mc-embedded-subscribe"
                            :disabled="false"
                        >
                    </div>
                    <p class="form-field__error text-sm mb-0" v-show="errorMessage">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

defineProps({
    text: {
        type: String
    }
});

const schema = yup.object({
    EMAIL: yup.string().required().email().label('Email address')
});

const { submitForm } = useForm({ validationSchema: schema });
const { errorMessage, value: email } = useField('EMAIL');
</script>