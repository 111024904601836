import { defineAsyncComponent } from 'vue'

import AddressForm from '@/components/AddressForm.vue'
import AddressFormFields from '@/components/AddressFormFields.vue'
import FormButton from '@/components/FormButton.vue'
import GeoLookup from '@/components/GeoLookup.vue'
import GuestCheckoutForm from '@/components/GuestCheckoutForm.vue'
import LoginForm from '@/components/LoginForm.vue'
import MiniQuoteForm from '@/components/MiniQuoteForm.vue'
import NewsletterForm from '@/components/NewsletterForm.vue'
import QuoteForm from '@/components/QuoteForm.vue'
import Review from '@/components/Review.vue'
import SearchButton from '@/components/SearchButton.vue'

import IconAdd from '@/components/icons/IconAdd.vue'
import IconCalculate from '@/components/icons/IconCalculate.vue'
import IconCart from '@/components/icons/IconCart.vue'
import IconCheckout from '@/components/icons/IconCheckout.vue'
import IconChevron from '@/components/icons/IconChevron.vue'
import IconFacebook from '@/components/icons/IconFacebook.vue'
import IconGoogleplus from '@/components/icons/IconGoogleplus.vue'
import IconHelp from '@/components/icons/IconHelp.vue'
import IconLinkedin from '@/components/icons/IconLinkedin.vue'
import IconPaySecure from '@/components/icons/IconPaySecure.vue'
import IconTwitter from '@/components/icons/IconTwitter.vue'

export default {
    AddressForm,
    AddressFormFields,
    BurgerMenu: defineAsyncComponent(() => import(/* webpackChunkName: "burger-menu" */'@/components/BurgerMenu.vue')),
    ClickThroughGuard: defineAsyncComponent(() => import(/* webpackChunkName: "click-through-guard" */'@/components/ClickThroughGuard.vue')),
    FormButton,
    GeoLookup,
    GuestCheckoutForm,
    Help: defineAsyncComponent(() => import(/* webpackChunkName: "help" */'@/components/Help.vue')),
    HotspotMap: defineAsyncComponent(() => import(/* webpackChunkName: "hotspot-map" */'@/components/HotspotMap.vue')),
    IconAdd,
    IconCalculate,
    IconCart,
    IconCheckout,
    IconChevron,
    IconFacebook,
    IconGoogleplus,
    IconHelp,
    IconLinkedin,
    IconPaySecure,
    IconTwitter,
    LoginForm,
    MiniQuoteForm,
    MobileNav: defineAsyncComponent(() => import(/* webpackChunkName: "mobile-nav" */'@/components/MobileNav.vue')),
    NewsletterForm,
    ProgressBar: defineAsyncComponent(() => import(/* webpackChunkName: "progress-bar" */'@/components/ProgressBar.vue')),
    Questions: defineAsyncComponent(() => import(/* webpackChunkName: "questions" */'@/components/Questions.vue')),
    QuotationPersonalDetails: defineAsyncComponent(() => import(/* webpackChunkName: "quotation-personal-details" */'@/components/QuotationPersonalDetails.vue')),
    QuotationServices: defineAsyncComponent(() => import(/* webpackChunkName: "quotation-services" */'@/components/QuotationServices.vue')),
    QuotationVehicle: defineAsyncComponent(() => import(/* webpackChunkName: "quotation-vehicle" */'@/components/QuotationVehicle.vue')),
    QuoteForm,
    Review,
    SearchButton
}
