export default (function () {
  function find(selector = null) {
    if (selector) {
      return document.querySelector(selector);
    }
  }

  function findAll(selector = null) {
    if (selector) {
      return Array.prototype.slice.call(document.querySelectorAll(selector));
    }
  }

  const Dom = {
    body: find("body"),
    btnMeetTeam: find(".js-meet-team"),
    hiddenTeam: findAll(".meet-the-team .meet-the-team__item.hidden"),
    navTopLevelLinks: findAll(".nav-main__l1 a[data-gotonav]"),
    megaMenuPanels: findAll("[data-nav]"),
    megaMenuLinks: findAll(".nav-main__l2 a[data-gotonav]"),
    innerSubMenus: findAll(".nav-main__l2__inner"),
    inputs: findAll("input"),
    keyParts: find(".js-key-parts"),
    productVariantInputFields: findAll(
      'input[name="purchasableId"][type="radio"]'
    ),
    productVariantInfoSections: findAll(".purchaseable-variant[data-variant]"),
    productVariantImages: findAll("img[data-image-variant"),
    paymentIntentsForms: findAll(".stripe-payment-intents-form"),
  };

  return Dom;
})();
