<template>
    <form 
        ref="form"
        class="quote-form" 
        method="POST" 
        accept-charset="UTF-8" 
        enctype="multipart/form-data" 
        @submit.prevent="handleSubmit"
    >
        <slot></slot>

        <slot name="quote-form"></slot>
    </form>
</template>

<script setup>
import { ref } from 'vue'

const form = ref(null)
const submitted = ref(false)
function handleSubmit() {
    if (!submitted.value) {
        submitted.value = true
        form.value.submit()
    }
}
</script>