import { reactive, readonly } from 'vue'

const state = reactive({
    percentCompleted: 0
})

function setPercentCompleted(val) {
    state.percentCompleted = val
}

export default {
    state: readonly(state),
    setPercentCompleted
}