<template>
    <div class="review" v-if="text">
        <div class="review__top">
            <h3 class="review__name">{{ name }}</h3>
            <div class="review__stars">
                <i v-for="n in parseInt(rating)" :key="n" class="fas fa-star"></i>
            </div>
            <div class="absolute top-0 right-0 translate-x-2 -translate-y-2">
                <svg id="review-tick" viewBox="0 0 187.87 187.86">
                    <g>
                        <path fill="#42bc4c"
                            d="M77.75,21.45,82.4,9.37a14.6,14.6,0,0,1,27.52.73l4,12.31a14.61,14.61,0,0,0,18.42,9.34,13.54,13.54,0,0,0,1.38-.53L145.56,26a14.61,14.61,0,0,1,18.95,20l-5.86,11.55a14.59,14.59,0,0,0,6.4,19.63,13.94,13.94,0,0,0,1.37.61l12.08,4.66a14.6,14.6,0,0,1-.73,27.52l-12.31,4a14.61,14.61,0,0,0-9.36,18.41c.16.47.34.93.54,1.39l5.24,11.83a14.6,14.6,0,0,1-20,18.94l-11.55-5.86a14.61,14.61,0,0,0-19.64,6.4c-.22.45-.43.91-.61,1.37l-4.65,12.08A14.6,14.6,0,0,1,78,177.76l-4-12.31a14.6,14.6,0,0,0-18.41-9.36,13.71,13.71,0,0,0-1.39.53l-11.83,5.25a14.6,14.6,0,0,1-18.94-20l5.85-11.55a14.59,14.59,0,0,0-6.4-19.63,13,13,0,0,0-1.37-.61L9.37,105.45a14.6,14.6,0,0,1,.73-27.52l12.31-4a14.59,14.59,0,0,0,9.34-18.41,13.71,13.71,0,0,0-.53-1.39L26,42.29a14.6,14.6,0,0,1,20-18.94l11.55,5.86a14.61,14.61,0,0,0,19.64-6.37A14.36,14.36,0,0,0,77.75,21.45Z">
                        </path>
                        <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="9px"
                            d="M61.12,102.63,79,120.47,124.46,75"></path>
                    </g>
                </svg>
            </div>
        </div>
        <span v-if="date" class="review__date">{{ date }}</span>
        <p class="review__body" v-html="ellipsedText"></p>
        <div v-if="requiresEllipse" class="flex-grow flex flex-col justify-end items-center mt-3">
            <button @click.prevent="toggleEllipse" type="button"
                class="text-center text-sm text-orange focus:outline-none focus:underline hover:underline cursor-pointer"
                v-html="btnText"></button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    name: {
        type: String
    },
    text: {
        type: String
    },
    rating: {
        type: [String, Number]
    },
    date: {
        type: String
    }
});

// Quick fix implemented to account for text with \" in it.
const parsedText = computed(() => JSON.parse(JSON.stringify(props.text)));

const requiresEllipse = computed(() => parsedText.value ? parsedText.value.split(' ').length > 26 : false);
const isEllipsed = ref(true);
const btnText = computed(() => isEllipsed.value ? 'Show More' : 'Show Less');
function toggleEllipse() {
    isEllipsed.value = !isEllipsed.value
};

const ellipsedText = computed(() => {
    const text = parsedText.value;
    if (text) {
        if (isEllipsed.value && requiresEllipse.value) {
            return text.split(' ').slice(0, 25).join(' ') + '...';
        }
        return text;
    }
    return ''
});
</script>