import StripeForm from "./StripeForm"

/**
 * @property {string} publishableKey
 * @property {HTMLElement} container
 * @property {object} stripeInstance
 * @property {object} elements
 * @property {HTMLElement} messageContainer
 * @property {object} formInstance
 * @method isAllowedKey
 * @method createStripeInstance
 * @method displayPaymentForm
 * @method displayStripeMessage
 * @method displayMessage
 * @method perform3dsAuthentication
 */
class StripePaymentIntents {
    constructor(obj) {
        // Defaults
        this.stripeInstance = null
        this.formInstance = null
        this.elements = null

        // Set instance properties
        for (let key in obj) {
            if (this.isAllowedKey(key)) {
                this[key] = obj[key]
            }
        }

        if (this.container) {
            this.messageContainer = this.container.querySelector('.card-errors')
        }

        // Init Events
        this.createStripeInstance()

        // Method Bindings
        this.displayPaymentForm = this.displayPaymentForm.bind(this)
        this.displayStripeMessage = this.displayStripeMessage.bind(this)
        this.displayMessage = this.displayMessage.bind(this)
        this.perform3dsAuthentication = this.perform3dsAuthentication.bind(this)
    }

    isAllowedKey(key) {
        const allowedKeys = ['publishableKey', 'container']
        if (allowedKeys.includes(key)) {
            return true
        }
        return false
    }

    createStripeInstance() {
        if (this.publishableKey) {
            this.stripeInstance = Stripe(this.publishableKey)
        }
    }

    displayPaymentForm() {
        if (this.container) {
            const formFields = this.container.querySelector('.payment-form-fields')
            if (formFields) {
                formFields.classList.remove('hidden')
    
                this.elements = this.stripeInstance.elements()
                
                // const style = {
                //     base: {
                //         fontSize: '24px',
                //         lineHeight: '1.15'
                //     }
                // }
    
                // Create an instance of the card element
                const card = this.elements.create('card', {
                    // style,
                    hidePostalCode: true
                })
    
                card.addEventListener('change', this.displayStripeMessage)
    
                // Add the instance of the card element into the DOM (fieldset.card-data in this case)
                const cardContainer = formFields.querySelector('.card-data')
                if (cardContainer) {
                    card.mount(cardContainer)
                }

                // Get the form wrapping these fields and create the submit event callback
                const formElement = this.container.closest('form')
                if (formElement) {
                    this.formInstance = new StripeForm(formElement)
                    this.formInstance.onSubmit(async () => {
                        try {
                            // If client secret is present, that's a pretty good indicator that things should be handled on page.
                            if (this.container.dataset.clientSecret) {
                                this.perform3dsAuthentication(card)
                                return
                            }

                            // It's required that the user fills in the name field. If they haven't, throw an error before we go any further.
                            if (!this.formInstance.paymentData.billing_details.name) {
                                // throw 'The First Name and Last Name fields are required'
                                throw 'The Name field is required'
                            }
    
                            // if we do not have a client secret, Tokenize the credit card details and create a payment source
                            const { paymentMethod, error } = await this.stripeInstance.createPaymentMethod('card', card, this.formInstance.paymentData)
    
                            if (error) {
                                throw error.message
                            } else {
                                // Add the payment source token to the form.
                                const paymentMethodInput = `<input type="hidden" name="paymentForm[stripe][paymentMethodId]" value="${paymentMethod.id}"/>`
                                formElement.insertAdjacentHTML('beforeend', paymentMethodInput)
                                formElement.submit()
                            }
                        } catch (errorMessage) {
                            this.displayMessage(errorMessage)
                            this.formInstance.isProcessing(false)
                        }
                    })
                }
            }
        }
    }

    displayStripeMessage({ error }) {
        if (error) {
            this.displayMessage(error.message)
        } else {
            this.displayMessage('')
        }
    }

    displayMessage(message) {
        if (this.messageContainer) {
            this.messageContainer.textContent = ''
            if (message) {
                const messageMarkup = `<p class="px-3 py-2">${message}</p>`
                this.messageContainer.insertAdjacentHTML('afterbegin', messageMarkup)
            } else {
                this.messageContainer.textContent = message
            }
        }
    }

    async perform3dsAuthentication(card) {
        if (card) {
            this.displayMessage('Please wait, processing payment...')
            const { error } = await this.stripeInstance.handleCardPayment(this.container.dataset.clientSecret, card)
            if (error) {
                this.displayMessage(error.message)
                this.displayPaymentForm()
            } else {
                location.reload()
            }
        }
    }

}

export default StripePaymentIntents