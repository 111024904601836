<template>
    <form 
        ref="form"
        method="POST" 
        accept-charset="UTF-8" 
        data-saveshortcut="1" 
        id="mini-quote-form" 
        @submit.prevent="onSubmit"
    >
        <slot name="form-hidden-inputs"></slot>

        <div class="mb-4">
            <input 
                type="text" 
                placeholder="Vehicle Registration" 
                name="quotation[your-vehicle][vehicleRegistration]" 
                v-model="reg" 
            />
            <p v-show="regErrorMessage" class="form-field__error">{{ regErrorMessage }}</p>
        </div>

        <GeoLookup class="mb-4" v-model="postcode" :error-message="postcodeErrorMessage" />

        <div class="mb-4">
            <div class="select">
                <select 
                    class="h-12 text-xl border border-orange p-0 px-2 rounded-sm" 
                    :class="{
                        'text-black': service, 
                        'text-grey-dark': !service
                    }"
                    name="quotation[service-required][serviceParent]" 
                    v-model="service"
                >
                    <option value="" disabled selected>Service Required</option>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.title }}</option>
                </select>
            </div>
            <p v-show="serviceErrorMessage" class="form-field__error">{{ serviceErrorMessage }}</p>
        </div>

        <p v-show="showIfCanProceed && !canProceed" class="text-red-600">All fields are required to proceed.</p>

        <div class="flex relative">
            <button class="button button--chevron w-full rounded-sm transition-none" :class="{ 'is-loading is-loading--small is-loading--white': canProceed }" type="submit">Next <IconChevron /></button>
            
            <a :href="`https://api.whatsapp.com/send?phone=${whatsAppNumber}`" target="_blank" class="bg-green-whatsapp hover:bg-green-550 rounded-full text-white hover:text-white focus:text-white text-32 flex justify-center items-center flex-shrink-0 absolute right-0 top-1/2  -translate-y-1/2 overflow-visible" style="width: 3.625rem; height: 3.625rem;"><i class="fab fa-whatsapp"></i></a>
        </div>
    </form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import Yup from '@/config/validation'
import GeoLookup from '@/components/GeoLookup.vue'
import IconChevron from '@/components/icons/IconChevron.vue'

defineProps({
    services: {
        type: Array,
        required: true
    },
    whatsAppNumber: {
        type: [String, Number],
        required: true
    }
});

const cookieName = 'saved-vehicle-registration';
const showIfCanProceed = ref(false);
const canProceed = ref(false);

const validationSchema = Yup.object({
    'quotation[your-vehicle][vehicleRegistration]': Yup.string().required().label('Vehicle Registration'),
    'quotation[service-required][serviceParent]': Yup.string().required().label('Service'),
    'quotation[your-details][postCode]': Yup.string().isPostcodeCovered().isUkPostcode().required().label('Postcode')
})
const { handleSubmit } = useForm({ validationSchema });
const { value: reg, errorMessage: regErrorMessage } = useField('quotation[your-vehicle][vehicleRegistration]', undefined, { initialValue: getSavedReg() })
const { value: service, errorMessage: serviceErrorMessage } = useField('quotation[service-required][serviceParent]', undefined, { initialValue: '' })
const { value: postcode, errorMessage: postcodeErrorMessage } = useField('quotation[your-details][postCode]', undefined, { initialValue: '' })

// Set the vehicle registration to the value stored in the cookie if it is present
function getSavedReg() {
    const decodedCookie = decodeURIComponent(document.cookie)
    const savedReg = decodedCookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    return (savedReg && savedReg.length > 2) ? savedReg[2] : ''
}

// Form submission functions
function onInvalidSubmit({ values, errors, results }) {
    showIfCanProceed.value = true
    canProceed.value = false
}

const form = ref(null);
function onValidSubmit(values) {
    canProceed.value = true
    // Set registration cookie in preparation for the users next visit
    document.cookie = `${cookieName}=${reg.value}; expires=${new Date(2147483647 * 1000).toUTCString()}`
    form.value.submit()
}

const onSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);
</script>

<style lang="scss">
    #mini-quote-form {
        input {
            @apply border border-orange h-12 px-3 rounded-sm;

            &::-moz-placeholder {
            @apply text-grey-dark text-xl;
            }

            &::-webkit-input-placeholder {
            @apply text-grey-dark text-xl;
            }

            &::placeholder {
            @apply text-grey-dark text-xl;
            }
        }

        button {
            &[type="submit"] {
                background: none;
                background-color: transparent;
                background-image: url("@/img/buttons/next-button-circle-cutout.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                width: calc(100% - 3.5rem);

                &:hover {
                    background-image: url("@/img/buttons/next-button-circle-cutout-hover.svg");
                }
            }
        }
    }
</style>