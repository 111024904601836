<template>
    <form method="POST">
        <slot name="hidden-inputs"></slot>

        <div class="row">
            <!-- First Col -->
            <div class="col w-full lg:w-1/2 lg:pr-4">
                <!-- Email (if required) -->
                <div v-if="emailRequired" class="mb-4">
                    <label for="email-input">Email</label>
                    <input
                        class="u-full-width"
                        id="email-input"
                        type="text"
                        :name="EMAIL"
                        v-model="email"
                        placeholder="email@site.com"
                    />
                    <span v-if="emailErrorMessage" class="block bg-red-100 text-red-800 p-2 px-3 rounded-b">{{ emailErrorMessage }}</span>
                </div>

                <!-- Select/create shipping address -->
                <AddressFormFieldset
                    class="ShippingAddress"
                    heading="Shipping Address"
                    field-name="shippingAddress"
                    :initial-values="initialValues.shippingAddress"
                    :initial-errors="initialErrors.shippingAddress"
                />

                <!-- Use same address for billing -->
                <label for="sameAddress" class="inline-block cursor-pointer mt-4">
                    <input
                        id="sameAddress"
                        :name="SAME_ADDRESS"
                        type="checkbox"
                        v-model="billingAddressSameAsShipping"
                    />
                    <span class="label-body">Use same address for billing</span>
                </label>
                <input type="hidden" name="billingAddressSameAsShipping" :value="billingAddressSameAsShipping ? '1' : '0'">
            </div>

            <!-- Second Col -->
            <div class="col w-full lg:w-1/2 lg:pl-4" v-if="!billingAddressSameAsShipping">
                <!-- Select/create billing address -->
                <AddressFormFieldset
                    class="BillingAddress mt-8 lg:mt-0"
                    heading="Billing Address"
                    field-name="billingAddress"
                    :initial-values="initialValues.billingAddress"
                    :initial-errors="initialErrors.billingAddress"
                />
            </div>
        </div>

        <div class="flex w-full justify-end pb-4 mt-8">
            <button type="submit" class="btn" :disabled="!isValid">Confirm addresses</button>
        </div>
    </form>
</template>

<script setup>
import { ref, provide, computed } from 'vue';
import { useField } from 'vee-validate';
import * as yup from 'yup';
import AddressFormFieldset from '@/components/AddressFormFieldset.vue';

const props = defineProps({
    isLoggedIn: {
        type: Boolean,
        required: true
    },
    emailRequired: {
        type: Boolean,
        required: true
    },
    initialValues: {
        type: Object,
        required: true
    },
    initialErrors: {
        type: Object,
        required: true
    },
    countries: {
        type: Object,
        required: true
    },
    addresses: {
        type: Array,
        required: true
    }
});
provide('isLoggedIn', props.isLoggedIn);
provide('addresses', props.addresses);
provide('countries', props.countries);

// Form valid state and update logic
const invalidChildComponentIds = ref([]);
const isValid = computed(() => !invalidChildComponentIds.value.length && (props.emailRequired ? emailMeta.valid : true));
function updateIsValid(val, componentUID) {
    invalidChildComponentIds.value = invalidChildComponentIds.value.filter(id => id !== componentUID)
    if (!val) invalidChildComponentIds.value.push(componentUID)
};
provide('updateIsValid', updateIsValid);

// Form fields
const EMAIL = 'email';
const SAME_ADDRESS = 'sameAddress';

// Form field data bindings
const { 
    value: email, 
    errorMessage: emailErrorMessage, 
    meta: emailMeta,
    setErrors: setEmailErrors
} = useField(EMAIL, props.emailRequired ? yup.string().required().email().label('Email') : null, { initialValue: props.initialValues.email });
setEmailErrors(props.initialErrors.email)
const { 
    value: billingAddressSameAsShipping 
} = useField(SAME_ADDRESS, null, { initialValue: props.initialValues.useSameAddress });
</script>