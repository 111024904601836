<template>
    <div class="addressBox" :class="modelName">
        <div class="row">
            <!-- First Name -->
            <div class="col w-full sm:w-1/2 mb-5">
                <label :for="fieldNames.firstName" class="text-32 mb-0">First Name *</label>
                <input 
                    class="w-full" 
                    type="text" 
                    :id="fieldNames.firstName" 
                    :name="fieldNames.firstName" 
                    v-model="firstName"
                >
                <p v-if="firstNameErrorMessage" class="form-field__error">{{ firstNameErrorMessage }}</p>
            </div>

            <!-- Last Name -->
            <div class="col w-full sm:w-1/2 mb-5">
                <label :for="fieldNames.lastName" class="text-32 mb-0">Last Name *</label>
                <input 
                    class="w-full" 
                    type="text" 
                    :id="fieldNames.lastName" 
                    :name="fieldNames.lastName" 
                    v-model="lastName"
                >
                <p v-if="lastNameErrorMessage" class="form-field__error">{{ lastNameErrorMessage }}</p>
            </div>

            <input type="hidden" :name="fieldNames.fullName" :id="fieldNames.fullName" v-model="fullName">
        </div>

        <!-- Address 1 -->
        <div class="mb-5">
            <label :for="fieldNames.addressLine1" class="text-32 mb-0">Address 1 *</label>
            <input 
                class="w-full" 
                type="text" 
                :id="fieldNames.address1"
                :name="fieldNames.address1"
                v-model="address1"
            >
            <p v-if="address1ErrorMessage" class="form-field__error">{{ address1ErrorMessage }}</p>
        </div>

        <!-- Address 2 -->
        <div class="mb-5">
            <label :for="fieldNames.address2" class="text-32 mb-0">Address 2</label>
            <input 
                class="w-full"
                type="text"
                :id="fieldNames.address2"
                :name="fieldNames.address2"
                v-model="address2"
            >
        </div>

        <!-- City -->
        <div class="mb-5">
            <label :for="fieldNames.city" class="text-32 mb-0">City *</label>
            <input 
                class="w-full" 
                type="text" 
                :id="fieldNames.city" 
                :name="fieldNames.city" 
                v-model="city"
            >
            <p v-if="cityErrorMessage" class="form-field__error">{{ cityErrorMessage }}</p>
        </div>

        <div class="row">
            <!-- Postcode -->
            <div class="col w-full sm:w-1/2 mb-5">
                <label :for="fieldNames.postcode" class="text-32 mb-0">Postcode *</label>
                <input 
                    class="w-full"
                    type="text"
                    :id="fieldNames.postcode"
                    :name="fieldNames.postcode"
                    v-model="postcode"
                >
                <p v-if="postcodeErrorMessage" class="form-field__error">{{ postcodeErrorMessage }}</p>
            </div>

            <!-- Phone -->
            <div class="col w-full sm:w-1/2 mb-5">
                <label :for="fieldNames.phone" class="text-32 mb-0">Phone *</label>
                <input 
                    class="w-full"
                    type="text"
                    :id="fieldNames.phone"
                    :name="fieldNames.phone"
                    v-model="phone"
                >
                <p v-if="phoneErrorMessage" class="form-field__error">{{ phoneErrorMessage }}</p>
            </div>
        </div>

        <!-- Country -->
        <div>
            <label :for="fieldNames.countryId" class="text-32 mb-0">Country *</label>
            <select 
                class="address-country w-full text-32 p-3" 
                :id="fieldNames.countryId"
                :name="fieldNames.countryId"
                v-model="country"
                disabled="disabled"
            >
                <option 
                    v-for="(country, id) in countries" 
                    :key="id"
                    :value="id"
                >{{ country }}</option>
            </select>
            <p v-if="countryErrorMessage" class="form-field__error">{{ countryErrorMessage }}</p>
            <input type="hidden" :name="fieldNames.countryId" :value="country">
        </div>
    </div>
</template>

<script setup>
import { computed, inject, onUnmounted, watch, ref } from 'vue'
import { useField } from 'vee-validate'
import * as yup from 'yup'
import uniqueId from 'lodash/uniqueId';

const props = defineProps({
    modelName: {
        type: String,
        required: true
    },
    countries: {
        type: Object,
        required: true
    },
    initialValues: {
        validator: (val) => typeof val === 'object' || val === null,
        required: true
    },
    initialErrors: {
        validator: (val) => typeof val === 'object' || val === null,
        required: true
    }
});

// Form fields
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const FULL_NAME = 'fullName'
const ADDRESS_1 = 'addressLine1';
const ADDRESS_2 = 'addressLine2';
const CITY = 'locality';
const POSTCODE = 'postalCode';
const PHONE = 'addressPhone';
const COUNTRY_ID = 'countryCode';
const fieldNames = props.modelName ? {
    firstName: `${props.modelName}[${FIRST_NAME}]`,
    lastName: `${props.modelName}[${LAST_NAME}]`,
    fullName: `${props.modelName}[${FULL_NAME}]`,
    address1: `${props.modelName}[${ADDRESS_1}]`,
    address2: `${props.modelName}[${ADDRESS_2}]`,
    city: `${props.modelName}[${CITY}]`,
    postcode: `${props.modelName}[${POSTCODE}]`,
    phone: `${props.modelName}[fields][${PHONE}]`,
    countryId: `${props.modelName}[${COUNTRY_ID}]`
    } : {
    firstName: `${FIRST_NAME}`,
    lastName: `${LAST_NAME}`,
    fullName: `${FULL_NAME}`,
    address1: `${ADDRESS_1}`,
    address2: `${ADDRESS_2}`,
    city: `${CITY}`,
    postcode: `${POSTCODE}`,
    phone: `fields[${PHONE}]`,
    countryId: `${COUNTRY_ID}`
    }

// Form field data bindings, validation rules and initial values
const { 
    value: firstName, 
    errorMessage: firstNameErrorMessage, 
    meta: firstNameMeta,
    setErrors: setFirstNameErrors
} = useField(fieldNames.firstName, yup.string().required().label('First Name'), { initialValue: props.initialValues?.[FIRST_NAME] });
setFirstNameErrors(props.initialErrors?.[FIRST_NAME])

const { 
    value: lastName,
    errorMessage: lastNameErrorMessage,
    meta: lastNameMeta,
    setErrors: setLastNameErrors
} = useField(fieldNames.lastName, yup.string().required().label('Last Name'), { initialValue: props.initialValues?.[LAST_NAME] });
setLastNameErrors(props.initialErrors?.[LAST_NAME])

const { 
    value: address1,
    errorMessage: address1ErrorMessage,
    meta: address1Meta,
    setErrors: setAddress1Errors
} = useField(fieldNames.address1, yup.string().required().label('Address 1'), { initialValue: props.initialValues?.[ADDRESS_1] });
setAddress1Errors(props.initialErrors?.[ADDRESS_1])

const address2 = ref(props.initialValues ? props.initialValues.addressLine2 : null);

const { 
    value: city,
    errorMessage: cityErrorMessage,
    meta: cityMeta,
    setErrors: setCityErrors
} = useField(fieldNames.city, yup.string().required().label('City'), { initialValue: props.initialValues?.[CITY] });
setCityErrors(props.initialErrors?.[CITY])

const { 
    value: postcode,
    errorMessage: postcodeErrorMessage,
    meta: postcodeMeta,
    setErrors: setPostcodeErrors
} = useField(fieldNames.postcode, yup.string().required().label('Postcode'), { initialValue: props.initialValues?.[POSTCODE] });
setPostcodeErrors(props.initialErrors?.[POSTCODE])

const { 
    value: phone,
    errorMessage: phoneErrorMessage,
    meta: phoneMeta,
    setErrors: setPhoneErrors
} = useField(fieldNames.phone, yup.number().required().typeError('${path} must be a number').label('Phone'), { initialValue: props.initialValues?.[PHONE] });
setPhoneErrors(props.initialErrors?.[PHONE])

const { 
    value: country, 
    errorMessage: countryErrorMessage,
    meta: countryMeta,
    setErrors: setCountryErrors
} = useField(fieldNames.countryId, yup.string().required().label('Country'), { initialValue: props.initialValues?.[COUNTRY_ID] || 'GB' });
setCountryErrors(props.initialErrors?.[COUNTRY_ID])

const fullName = computed(() => firstName.value + ' ' + lastName.value)

// Expose whether or not all of the form fields are valid
const areAllFieldsValid = computed(() => (
    firstNameMeta.valid &&
    lastNameMeta.valid &&
    address1Meta.valid &&
    cityMeta.valid &&
    postcodeMeta.valid &&
    phoneMeta.valid &&
    countryMeta.valid
));
const emit = defineEmits(['validity-change'])
const exposeValidity = inject('updateIsValid', null)
const UID = uniqueId()
function handleValidityChange(val) {
    if (exposeValidity && typeof exposeValidity === 'function') exposeValidity(val, UID)
    emit('validity-change', val)
}
watch(() => areAllFieldsValid.value, handleValidityChange, { immediate: true });
onUnmounted(() => { handleValidityChange(true) });
</script>