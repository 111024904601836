import StripePaymentIntents from "./StripePaymentIntents"

/**
 * @property {string} publishableKey
 * @property {string} scenario
 * @property {array} paymentIntentsForms
 * @method isAllowedKey
 * @method init
 */
class StripePaymentGateway {
    constructor(obj) {
        // Defaults
        this.publishableKey = null
        this.scenario = null

        // Set instance properties
        for (let key in obj) {
            if (this.isAllowedKey(key)) {
                this[key] = obj[key]
            }
        }
    }

    isAllowedKey(key) {
        const allowedKeys = ['paymentIntentsForms']
        if (allowedKeys.includes(key)) {
            return true
        }
        return false
    }

    init() {
        if (typeof Stripe === "undefined") {
            setTimeout(this.init, 150);
        } else {
            if (this.paymentIntentsForms) {
                this.paymentIntentsForms.forEach(container => {
                    this.publishableKey = container.dataset.publishablekey || null
                    this.scenario = container.dataset.scenario || null
                    
                    if (this.publishableKey && this.scenario) {
                        const handlerParams = {
                            publishableKey: this.publishableKey,
                            container
                        }
                        const handlerInstance = new StripePaymentIntents(handlerParams);

                        // From the old code. This was happening at this stage in the old code but I can't see in the current setup why this would be important/needed as everything seems to be working fine
                        // $container.data('handlerInstance', handlerInstance);

                        if (this.scenario === 'payment') {
                            handlerInstance.displayPaymentForm()
                        }

                        if (this.scenario === '3ds') {
                            handlerInstance.perform3dsAuthentication()
                        }
                    }
                })
            }
        }
    }

}

export default StripePaymentGateway