<template>
    <form method="POST" @submit="submitForm">
        <slot name="hidden-inputs"></slot>

        <div>
            <label class="text-2xl mb-0" for="guest-email">Email</label>
            <div class="mb-4">
                <input
                    id="guest-email"
                    type="email"
                    :name="EMAIL"
                    placeholder="email@site.com"
                    required
                    v-model="value"
                />
                <p v-if="errorMessage" class="bg-red-100 text-red-800 p-2 px-3 rounded-b mb-4">{{ errorMessage }}</p>
            </div>
            <div class="flex justify-between items-center">
                <span v-if="serverErrorMessage && !meta.dirty" class="block text-xl text-red-600">{{ serverErrorMessage }}</span>
                <br />
                <button type="submit" class="btn ml-auto flex-shrink-0">Continue as Guest</button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

defineProps({
    serverErrorMessage: {
        type: String,
        default: ''
    }
});

const EMAIL = 'email'
const validationSchema = {
    [EMAIL]: string().required('${path} is required to checkout as a guest').email().label('Email')
}
const { submitForm } = useForm({ validationSchema })
const { value, errorMessage, meta } = useField(EMAIL);
</script>