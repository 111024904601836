<template>
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="561px"
		height="561px"
		viewBox="0 0 561 561"
		style="enable-background:new 0 0 561 561;"
		xml:space="preserve"
	>
		<g>
			<g id="gps-fixed">
				<path
					d="M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102
			C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55
			C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55
			C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102
			S459,181.05,459,280.5S379.95,459,280.5,459z"
				/>
			</g>
		</g>
	</svg>
</template>

<script setup>
</script>