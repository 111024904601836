<template>
    <div class="address-fieldset">
        <h1 class="mb-3 text-28 font-light text-black">{{ heading }}</h1>
        <template v-if="isLoggedIn && !!addresses.length">
            <!-- Current user's addresses -->
            <div 
                v-for="address in addresses" 
                class="address-select mb-6 relative" 
                :data-address-id="address.id"
                :key="address.id"
            >
                <label class="radio cursor-pointer text-black">
                    <input 
                        type="radio" 
                        :name="addressRadioName" 
                        v-model="selectedAddress"
                        :value="address.id"
                    />
                    <div class="-mt-4 ml-6" v-html="address.markup"></div>
                    <div class="absolute top-4 right-3 text-sm bg-grey-lighter font-light py-1 px-3 rounded-sm" v-if="address.id === initialValues?.primaryId">Default {{ fieldName == "billingAddress" ? 'Billing' : 'Shipping' }} Address</div>
                </label>
                <div class="block mt-2 pl-6 flex justify-between">
                    <label class="mb-0" v-if="selectedAddress === address.id && addresses.length > 1" :for="'makePrimary' + addressCheckBoxName">
                        <input type="radio" :name="'makePrimary' + addressCheckBoxName" :id="'makePrimary' + addressCheckBoxName" value="1"/>
                        Make Primary {{ fieldName == "billingAddress" ? 'Billing' : 'Shipping' }} Address
                    </label>
                    <a class="edit ml-auto link text-base hover:text-orange" :href="address.editUrl">Edit</a>
                </div>
            </div>
        </template>

        <!-- New address -->
        <div class="address-select mt-6">
            <label class="cursor-pointer">
                <input
                    type="radio" 
                    :name="addressRadioName"
                    v-model="selectedAddress"
                    value=""
                />
                <span>New {{ heading }}</span>
            </label>

            <div class="body mt-4" v-if="!selectedAddress">
                <AddressFormFields
                    :modelName="fieldName"
                    :countries="countries"
                    :initial-values="initialValues"
                    :initial-errors="initialErrors"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, computed } from 'vue';
import { useField } from 'vee-validate';
import AddressFormFields from '@/components/AddressFormFields.vue';

const props = defineProps({
    heading: {
        type: String,
        required: true
    },
    fieldName: {
        type: String,
        required: true
    },
    initialValues: {
        validator: (val) => typeof val === 'object' || val === null,
        required: true
    },
    initialErrors: {
        validator: (val) => typeof val === 'object' || val === null,
        required: true
    }
});
const isLoggedIn = inject('isLoggedIn', false);
const countries = inject('countries', {});
const addresses = inject('addresses', []);
const addressRadioName = computed(() => `${props.fieldName}Id`);
const addressCheckBoxName = computed(() => props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1))
const { value: selectedAddress } = useField(addressRadioName.value, null, { initialValue: props.initialValues?.sourceId ?? '' });
</script>