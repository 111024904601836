/**
 * Performs an operation in mobile() or desktop() if the media condition is met, and continues to watch for when the condition will be met.
*/
export default class MatchMedia {
    mql = null
    callback = () => {}

    listen() {
        if (!this.mql) return

        this.invokeCallbackIfMatches(this.mql.matches)

        try {
            this.mql.addEventListener('change', event => this.invokeCallbackIfMatches(event.matches))
        } catch (error) {
            try {
                this.mql.addListener(event => this.invokeCallbackIfMatches(event.matches))
            } catch (error) {
                console.error(error)
            }
        }
    }

    invokeCallbackIfMatches(matches) {
        if (matches) {
            this.callback()
        }
    }

    mobile(callback) {
        this.mql = window.matchMedia('screen and (max-width: 767px)')
        this.callback = callback
        this.listen()
    }

    desktop(callback) {
        this.mql = window.matchMedia('screen and (min-width: 768px)')
        this.callback = callback
        this.listen()
    }
}