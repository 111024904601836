/**
 * @property {HTMLElement} form
 * @property {HTMLElement} submitBtn
 * @property {HTMLElement} orderEmail
 * @property {HTMLElement} cardHolderFirstName
 * @property {HTMLElement} cardHolderLastName
 * @property {HTMLElement} cardHolderName
 * @property {HTMLElement} stripeAddress
 * @property {HTMLElement} line1
 * @property {HTMLElement} city
 * @property {HTMLElement} postCode
 * @property {HTMLElement} country
 * @property {HTMLElement} state
 * @property {object} paymentData
 * @property {boolean} processing
 * @property {function} submissionCallback
 * @method init
 * @method onSubmit
 * @method preparePaymentData
 * @method createSingleNameField
 * @method handleSubmit
 * @method isProcessing
 */
class StripeForm {
    constructor(formElement) {
        this.form = formElement || null
        if (this.form) {
            this.submitBtn = this.form.querySelector('button[type="submit"]')
            this.orderEmail = this.form.querySelector('input[name="orderEmail"]')
            this.cardHolderFirstName = this.form.querySelector('.card-holder-first-name')
            this.cardHolderLastName = this.form.querySelector('.card-holder-last-name')
            this.stripeAddress = this.form.querySelector('.stripe-address')
            if (this.stripeAddress) {
                this.line1 = this.stripeAddress.querySelector('input[name="stripe-line1"]')
                this.city = this.stripeAddress.querySelector('input[name="stripe-city"]')
                this.postCode = this.stripeAddress.querySelector('input[name="stripe-postal-code"]')
                this.country = this.stripeAddress.querySelector('input[name="stripe-country"]')
                this.state = this.stripeAddress.querySelector('input[name="stripe-state"]')
            }
        }
        this.cardHolderName = null
        this.processing = false
        this.paymentData = null
        this.submissionCallback = null

        // Method Bindings
        this.createSingleNameField = this.createSingleNameField.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.preparePaymentData = this.preparePaymentData.bind(this)
        this.isProcessing = this.isProcessing.bind(this)

        // Init Events
        this.init()
    }

    init() {
        if (this.cardHolderFirstName && this.cardHolderLastName) {
            this.createSingleNameField()
        }
        if (this.form) {
            this.form.addEventListener('submit', this.handleSubmit)
        }
    }

    createSingleNameField() {
        const lastNameParent = this.cardHolderLastName.parentNode
        lastNameParent.className = 'card-holder-last-name-child-removed hidden' // Adding this here for ease of debugging in the future
        lastNameParent.removeChild(this.cardHolderLastName)
        this.cardHolderLastName = null

        const firstNameParent = this.cardHolderFirstName.parentNode
        firstNameParent.removeAttribute('class')
        firstNameParent.parentNode.removeAttribute('class')
        this.cardHolderFirstName.name = 'name'
        this.cardHolderFirstName.placeholder = 'Name On Card'

        this.cardHolderName = this.cardHolderFirstName
    }

    preparePaymentData() {
        // Compose card holder info and save on the instance
        let cardHolderName, orderEmail, ownerAddress

        if (this.cardHolderName) {
            if (this.cardHolderName.value) {
                cardHolderName = this.cardHolderName.value
            }
        }

        orderEmail = this.orderEmail && this.orderEmail.value

        ownerAddress = {
            'line1': this.line1 && this.line1.value,
            'city': this.city && this.city.value,
            'postal_code': this.postCode && this.postCode.value,
            'country': this.country && this.country.value,
            'state': this.state && this.state.value
        }

        this.paymentData = {
            billing_details: {
                name: cardHolderName,
                email: orderEmail,
                address: ownerAddress
            }
        }
    }

    isProcessing(bool = null) {
        if (bool !== null) {
            this.processing = bool ? true : false
            this.submitBtn.classList[ bool ? 'add' : 'remove' ]('is-loading', 'is-loading--white')
        }
        return this.processing
    }

    onSubmit(callback) {
        // Save the callback for later, to be used when handling the submit event
        this.submissionCallback = callback
    }

    handleSubmit(e) {
        e.preventDefault()

        // If form submitted already, disregard
        if (this.processing) {
            return false
        }
        this.isProcessing(true)

        this.preparePaymentData()

        // If we have attached a submission callback, invoke it now
        if (this.submissionCallback) {
            this.submissionCallback()
        }
    }

}

export default StripeForm